import { environment, apiEndpoint } from '@awork/environments/environment'
import { Injectable } from '@angular/core'
import { get as getCookie, set as setCookie } from 'es-cookie'
import { SubscriptionQuery } from '@awork/_shared/state/subscription.query'
import { AccountQuery } from '@awork/_shared/state/account.query'
import { AccountState } from '@awork/_shared/models/account.model'
import { WorkspaceQuery } from '@awork/features/workspace/state/workspace.query'
import { Workspace } from '@awork/features/workspace/models/workspace.model'
import { Observable, take, map, forkJoin, Subscription, filter } from 'rxjs'
import { ApiClient } from '@awork/_shared/services/api-client/ApiClient'
import { AppQuery } from '@awork/core/state/app.query'
import { LogService } from '@awork/_shared/services/log-service/log.service'
import { QSubscription } from '@awork/_shared/models/subscription.model'
import { BrowserService } from '@awork/_shared/services/browser-service/browser.service'
import getLanguage from '@awork/_shared/functions/get-language'
import { differenceInDays, differenceInYears } from '@awork/_shared/functions/date-fns-wrappers'
import { QEventService } from '@awork/_shared/services/q-event-service/q-event.service'
import { UserQuery } from '@awork/features/user/state/user.query'
import { ABTestService } from '@awork/_shared/services/ab-test-service/ab-test.service'
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router'
import getHostname from '@awork/_shared/functions/get-hostname'
import { TrackingEvent } from './events'
import { PermissionsQuery } from '@awork/features/workspace/state/permissions.query'
import { randomInt } from '@awork/core/mocks_stubs/mock.helpers'

// allow to use some of the global variables
declare var Intercom: any

export enum TrackingSegment {
  NpsRating = '6290c11f131af619b3bb9282'
}

@Injectable({ providedIn: 'root' })
export class TrackingService {
  analytics: any
  account: AccountState
  workspace: Workspace

  private trackAppAccessedSubscription: Subscription
  private readonly isLoggingSample = randomInt(50, 1) === 1

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private accountQuery: AccountQuery,
    private workspaceQuery: WorkspaceQuery,
    private subscriptionQuery: SubscriptionQuery,
    private appQuery: AppQuery,
    private apiClient: ApiClient,
    private logService: LogService,
    private browserService: BrowserService,
    private userQuery: UserQuery,
    private qEventService: QEventService,
    private permissionsQuery: PermissionsQuery,
    private abTestService: ABTestService
  ) {
    this.accountQuery.selectAccount().subscribe(account => (this.account = account))
    // get the current workspace
    this.workspaceQuery.selectCurrentWorkspace().subscribe(tm => {
      this.workspace = tm
    })

    this.getMarketingParams()
    this.initTracking()
    this.initTrackingOnSignUp()
  }

  /**
   * Gets the marketing parameters from the url and sets the marketing cookies
   */
  private getMarketingParams(): void {
    const url = new URL(window.location.href)
    const searchParams = new URLSearchParams(url.search)

    // Base64 encoded param
    if (searchParams.get('aw_ids')) {
      try {
        const decodedParam = atob(searchParams.get('aw_ids'))
        const decodedSearchParams = new URLSearchParams(decodedParam)

        for (const [key, value] of decodedSearchParams.entries()) {
          searchParams.set(key, value)
        }

        this.setQueryParams(searchParams)
      } catch (_) {}
    }

    this.setMarketingCookies(searchParams)
  }

  /**
   * Sets the decoded query params in the url
   * @param {URLSearchParams} searchParams
   */
  private setQueryParams(searchParams: URLSearchParams): void {
    if (!searchParams.size) {
      return
    }

    searchParams.delete('aw_ids')

    const queryParams: Params = {}

    for (let [key, value] of searchParams.entries()) {
      queryParams[key] = value
    }

    // The current path is needed to prevent the router from navigating to the root route (/)
    const currentPath = window.location.pathname

    this.router.navigate([currentPath], {
      relativeTo: this.route,
      queryParams,
      replaceUrl: true
    })
  }

  /**
   * Sets marketing cookies based on url parameters
   * @param {URLSearchParams} params
   */
  private setMarketingCookies(params: URLSearchParams): void {
    const hostname = getHostname()

    // Google
    if (params.get('gclid')) {
      setCookie('aw_gclid', params.get('gclid'), {
        domain: hostname
      })
    }

    // LinkedIn
    if (params.get('li_fat_id')) {
      setCookie('aw_li_fat_id', params.get('li_fat_id'), {
        domain: hostname
      })
    }

    // Facebook
    if (params.get('fbclid')) {
      setCookie('aw_fbclid', params.get('fbclid'), {
        domain: hostname
      })
    }

    // Facebook
    if (params.get('fbc')) {
      setCookie('aw_fbc', params.get('fbc'), {
        domain: hostname
      })
    }

    // Outbrain
    if (params.get('obclid')) {
      setCookie('aw_obclid', params.get('obclid'), {
        domain: hostname
      })
    }

    // Segment
    if (params.get('uid')) {
      setCookie('aw_ajs_user_id', params.get('uid'), {
        domain: hostname
      })
    } else {
      const ajsCookie = getCookie('ajs_user_id')
      if (ajsCookie) {
        setCookie('aw_ajs_user_id', ajsCookie, {
          domain: hostname
        })
      }
    }
  }

  /**
   * Initializes the tracking providers (Segment and Google) according to the workspace subscription
   * @private
   */
  private initTracking(): void {
    const isLoggedIn = this.appQuery.getIsLoggedIn()

    // If the user is logged in we can determine if paid plan from the subscription
    // Otherwise, we can rely on the workspace (coming from workspace by subdomain)
    const subscriptionInfo$: Observable<QSubscription | Workspace> = isLoggedIn
      ? this.subscriptionQuery.selectSubscription().pipe(filter(subscription => !!subscription.planId))
      : this.workspaceQuery.selectCurrentWorkspace()

    subscriptionInfo$.pipe(take(1)).subscribe(subscriptionInfo => {
      try {
        let integrations: { [key: string]: boolean }

        const isLoggedInPaidSubscription =
          subscriptionInfo instanceof QSubscription &&
          subscriptionInfo.isPaidPlan &&
          subscriptionInfo.status === 'Active'

        const isWorkspacePaidSubscription = subscriptionInfo instanceof Workspace && subscriptionInfo.isPaidPlan

        if (isLoggedInPaidSubscription || isWorkspacePaidSubscription) {
          integrations = { All: false, Intercom: true, Adtriba: true }
        } else {
          integrations = { All: true }
        }

        this.loadTrackingScript(integrations)
      } catch (error) {
        if (error instanceof Error) {
          this.logService.logError(error)
        }
      }
    })
  }

  /**
   * Initializes the tracking on sign-up form with all the Segment's integrations
   */
  private initTrackingOnSignUp(): void {
    this.router.events
      ?.pipe(
        filter(event => event instanceof NavigationEnd && (event.urlAfterRedirects || event.url).includes('/signup')),
        take(1)
      )
      .subscribe(() => {
        this.loadTrackingScript({ All: true })
      })
  }

  /**
   * Loads the tracking script
   * @param {{ [key: string]: boolean }} integrations
   */
  private loadTrackingScript(integrations: { [key: string]: boolean }): void {
    // Only load the tracking script in production environment and if it's not already loaded
    if (environment !== 'production' || !!this.analytics) {
      return
    }

    const win = <any>window

    // setting the analytics snippet (before analytics is loaded completely) here,
    // to ensure its available and events are buffered until the script is loaded
    this.analytics = win.analytics

    try {
      this.analytics.load('870xymeboOhuHPZM0xAaIPljWD2IZbLO', { integrations })
    } catch (e) {
      // reinitialise analytics.load method, for some reason is not being added from index.html
      win.analytics.load = function (t, e) {
        const n = document.createElement('script')
        n.type = 'text/javascript'
        n.async = !0
        n.src = 'https://cdn.segment.com/analytics.js/v1/' + t + '/analytics.min.js'
        const a = document.getElementsByTagName('script')[0]
        a.parentNode.insertBefore(n, a)
        win.analytics._loadOptions = e
      }

      this.analytics = win.analytics

      this.analytics.load('870xymeboOhuHPZM0xAaIPljWD2IZbLO', { integrations })
    }

    win.analytics.ready(() => {
      // setting the actual, ready loaded library to the service property
      this.analytics = win.analytics

      // init intercom
      win.intercomSettings = {
        hide_default_launcher: true
      }
      Intercom('boot', { app_id: 'eycuwjj0' })
    })
  }

  invokeWhenActive(callback: Function) {
    try {
      if (
        // disable tracking for:
        // 1. non-production environments
        (environment !== 'production' && environment !== 'staging') ||
        // 2. impersonated account sessions
        (this.account && this.account.isImpersonated) ||
        // 3. end-to-end tests
        getCookie('aw_test')
      ) {
        // tracking disabled
        // *do nothing*
      } else {
        // tracking enabled
        callback()
      }
    } catch (error) {
      if (error instanceof Error) {
        this.logService.logError(error)
      }
    }
  }

  trackEvent(eventName: TrackingEvent, properties = {}, callback = () => {}, userId?: string) {
    this.invokeWhenActive(() => {
      this.trackToLinkedIn(eventName)

      // Segment
      try {
        const subscription = this.subscriptionQuery.getSubscription()
        const eventDataProperties = {
          ...properties,
          Category: 'awork',
          Device: 'web',
          id: this.workspace?.id || null, // needed for adtriba tracking to unify the account data
          messageId: this.workspace?.id || null, // needed for the new facebook tracking
          company: this.workspace?.id || null, // needed for the mixpanel company event
          subscription_plan: subscription?.getCurrentPlan() || null,
          subscription_status: subscription?.status || null,
          in_trial: subscription?.isInTrial,
          in_basic_connect: subscription?.isBasicConnectPlan,
          in_connect_business_trial: subscription?.isBasicConnectInTrial
        }

        this.analytics?.track(
          eventName,
          eventDataProperties,
          {
            integrations: {
              All: true,
              Intercom: false, // we track this via the backend (awork apps tracking)
              'Google Analytics': this.includeEventForDestination(eventName, 'Google Analytics'),
              'Facebook Pixel': this.includeEventForDestination(eventName, 'Facebook Pixel')
            }
          },
          callback
        )

        // Track events in parallel via our own backend (to avoid ad-blockers)
        // ---
        // Currently used for mixpanel mainly. Some events need to be excluded, because they
        // are being tracking by the backend already as well. Otherwise the are in there twice.
        if (
          !['Signed Up', 'Trial Started', 'Invitation Accepted'].includes(eventName) &&
          this.appQuery.getIsLoggedIn()
        ) {
          this.trackEventCall(eventName, eventDataProperties, userId).subscribe()
        }

        if (eventName === TrackingEvent.onboardingCompleted) {
          this.logService.sendLogDNA('INFO', `Onboarding Completed ${this.account?.email}`)
        }
      } catch (err) {}
    })

    if (this.isDeveloperEnvironment()) {
      console.log(eventName, properties)
      if (callback) {
        callback()
      }
    }
  }

  /**
   * List of exclusions for tracking destinations in segment
   * @param event
   * @param destination
   */
  includeEventForDestination(event: string, destination: string): boolean {
    let excludedEvents: string[] = []

    switch (destination) {
      case 'Mixpanel':
        excludedEvents = ['Signed Up', 'Trial Started']
        if (excludedEvents.includes(event)) {
          return false
        }
        break
      case 'Intercom':
        break
      case 'Google Analytics':
        excludedEvents = ['Signed Up', 'Trial Started']
        if (excludedEvents.includes(event)) {
          return false
        }
        break
      case 'Facebook Pixel':
        excludedEvents = ['Signed Up', 'Onboarding Completed']
        if (excludedEvents.includes(event)) {
          return false
        }
        break
    }
    return true
  }

  /**
   * Tracks events to linkedin but only onboarding complete and signed up right now.
   * @param eventName
   */
  trackToLinkedIn(eventName: string): void {
    try {
      const lintrk = (window as any).lintrk

      if (lintrk instanceof Function) {
        if (eventName == 'Onboarding Completed') {
          lintrk('track', { conversion_id: 8384073 })
        } else if (eventName == 'Signed Up') {
          lintrk('track', { conversion_id: 8604305 })
        }
      }
    } catch (err) {
      this.logService.logError(new Error(`LinkedIn tracking: ${err}`))
    }
  }

  trackPage(url: string) {
    this.invokeWhenActive(() =>
      this.analytics?.page({
        properties: {
          url
        }
      })
    )
  }

  setAlias(id: string) {
    this.invokeWhenActive(() => {
      this.analytics?.alias(id)
      // this.analytics.flush()
    })
  }

  identifyUser(userId: string, userProperties = {}, callback = () => {}) {
    this.invokeWhenActive(() => {
      // Segment
      try {
        this.analytics?.identify(userId, userProperties, { Intercom: { hideDefaultLauncher: true } }, callback)

        // Tracking via our backend – Currently only used for Mixpanel
        // Data is being pseudonymized for Mixpanel because of DSGVO <- this is not true right now, because we send
        // the data in the BE anyway and we need it for the hubspot integration.
        // const pseudonymizedUserProperties = { ...userProperties }
        // delete pseudonymizedUserProperties['name']
        // delete pseudonymizedUserProperties['email']
        // delete pseudonymizedUserProperties['username']
        // delete pseudonymizedUserProperties['first_name']
        // delete pseudonymizedUserProperties['last_name']
        this.identifyUserCall(userProperties).subscribe()
      } catch (err) {}
    })

    if (this.isDeveloperEnvironment()) {
      console.log('Identify User', userId, userProperties)
      if (callback) {
        callback()
      }
    }
  }

  identifyGroup(groupId: string, groupProperties = {}) {
    this.invokeWhenActive(() => {
      this.analytics?.group(groupId, groupProperties)

      const groupDataProperties = {
        // needed for the mixpanel company consolidation
        ...groupProperties,
        company: this.workspace ? this.workspace.id : null
      }

      this.identifyGroupCall(groupDataProperties).subscribe()
    })
  }

  /**
   * Identify Group API Call
   * @param data
   */
  identifyGroupCall(data: {}): Observable<void> {
    const body = {
      data,
      context: this.setEventContextData()
    }
    return this.apiClient.post(`${apiEndpoint}/group`, body)
  }

  /**
   * Identify User API Call
   * @param data
   */
  identifyUserCall(data: {}): Observable<void> {
    const body = {
      data,
      context: this.setEventContextData()
    }

    return this.apiClient.post(`${apiEndpoint}/identify`, body)
  }

  resetDistinctId() {
    this.invokeWhenActive(() => {
      this.analytics?.reset()
    })
  }

  flush() {
    this.invokeWhenActive(() => this.analytics?.flush?.())
  }

  /**
   * Clears the identification state of the user (e.g when loging out)
   */
  clear(): void {
    try {
      Intercom('shutdown')
      this.analytics?.reset()
    } catch (err) {}
  }

  /**
   * Track Event API Call
   * @param eventName
   * @param data
   */
  trackEventCall(eventName: string, data: {}, userId?: string): Observable<void> {
    const body: { eventName: string; data: {}; context: {}; userId?: string } = {
      eventName,
      data,
      context: this.setEventContextData()
    }

    if (userId) {
      body.userId = userId
    }

    return this.apiClient.post(`${apiEndpoint}/track`, body)
  }

  /**
   * Track Event Without Being Logged API Call
   * @param eventName
   * @param data
   */
  trackEventWithoutBeingLoggedIn(eventName: TrackingEvent, data: {} = {}): Observable<void> {
    const body: { eventName: string; data: {}; context: {} } = {
      eventName,
      data: {
        ...data,
        abTestGroup: this.abTestService.getTestGroupKey()
      },
      context: this.setEventContextData()
    }

    if (this.isDeveloperEnvironment()) {
      console.log(eventName, data)
    }

    return this.apiClient.post(`${apiEndpoint}/trackevent`, body)
  }

  /**
   * Function which tracks the signup started event. Only after this is done,
   * the further redirection is allowed to be done.
   * @param {string} userId
   * @param {string} email
   * @param {boolean} emailNotFound
   * @param {boolean} isPaidTrial
   * @returns {Promise<void>}
   */
  trackSignUpStarted(userId: string, email: string, emailNotFound?: boolean, isPaidTrial?: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        // FALLBACK - if the tracking isn't done after 2 seconds, resolve the promise manually.
        const timeout = setTimeout(() => {
          resolve()
        }, 2000)

        // NOTE: Currently commented out because the website <-> awork tracking is inactive right now
        // If the user has already an account, reset the distinct Id to create a new user for tracking
        // if (!this.emailNotFound) {
        //   this.trackingService.resetDistinctId()
        // }
        // // Set the alias to convert the user from unknown to known
        // this.trackingService.setAlias(resp.userId)

        // Set the user to the creation user
        this.identifyUser(
          userId,
          {
            is_team_creator: true,
            device_category: this.browserService.getDeviceCategory()
          },
          () => {
            // Track Signup Event
            this.trackEvent(
              TrackingEvent.signedUp,
              {
                is_first_account: emailNotFound,
                sign_up_email: email
              },
              () => {
                clearTimeout(timeout)
                resolve()
              }
            )
            if (isPaidTrial) {
              this.trackEvent(TrackingEvent.trialStarted, {})
            }
          }
        )
      } catch (err) {
        resolve()
      }
    })
  }

  /**
   * Check if the user is in an specific segmentId
   * @param {TrackingSegments}
   * @returns {Observable<boolean>}
   */
  getIsInSegment(segment: TrackingSegment): Observable<boolean> {
    return this.apiClient
      .get<{ isInSegment: boolean }>(`${apiEndpoint}/me/intercom/isinsegment?segmentId=${segment}`)
      .pipe(map(response => response.isInSegment))
  }

  /**
   * Identifies the user and the group, and tracks the app accessed event
   */
  identifyAndTrackAppAccessed(): void {
    this.trackAppAccessedSubscription = forkJoin({
      user: this.userQuery.selectCurrentUser().pipe(take(1)),
      workspace: this.workspaceQuery.selectCurrentWorkspace().pipe(take(1)),
      permissions: this.permissionsQuery.selectUserPermissions().pipe(take(1)),
      accountSubscription: this.subscriptionQuery.selectSubscription().pipe(take(1)),
      referralCode: this.appQuery.selectReferralCode().pipe(take(1))
    }).subscribe(({ user, workspace, permissions, accountSubscription, referralCode }) => {
      // Track plan info
      let plan = ''
      let period = ''
      let projects: number = null
      let quota: number = null
      let status = ''
      let trialEnd: Date

      const subdomain = workspace.getDefaultSubdomain()

      try {
        if (accountSubscription) {
          if (accountSubscription.planId) {
            plan = QSubscription.getPlanName(accountSubscription.planId)
            period = accountSubscription.period.toString()
          }
          quota = accountSubscription.bookedSeats
          status = accountSubscription.status
          projects = accountSubscription.totalProjects
          trialEnd = accountSubscription.trialEnd
        }
      } catch (ex) {}

      // Track identity
      const account: AccountState = this.accountQuery.getAccount()
      const origin_via_connect = !!accountSubscription?.isConnectInitialPlanId

      // Track user info
      const userProperties = {
        // user info
        created_at: this.formatDate(user.createdOn),
        account_id: user.accountId,
        email: account.email,
        username: account.email,
        is_admin: permissions ? permissions.isAdmin : false,
        referral_code: referralCode,
        origin_via_connect,

        // personal infos
        name: user.fullName,
        first_name: user.firstName,
        last_name: user.lastName,
        language: getLanguage(),
        gender: user.gender,
        age: differenceInYears(new Date(), user.birthDate),

        // set up some test groups
        test_group: this.abTestService.isIntercomTestGroup(),
        ab_test_group: this.abTestService.getTestGroupKey(),

        // workspace info
        team_free_projects: projects,
        team_id: workspace.id,
        team_name: workspace.name,
        team_url: subdomain.name,
        team_created_at: this.formatDate(workspace.createdOn),
        team_plan_name: plan,
        team_plan_users: quota,
        team_plan_status: status,
        team_plan_period: period,
        team_trial_end: this.formatDate(trialEnd),
        team_age_days: differenceInDays(new Date(), workspace.createdOn)
      }

      // identify user
      this.identifyUser(user.id, userProperties)

      // identify group
      const groupProperties = {
        name: workspace.name,
        team_free_projects: projects,
        team_url: subdomain.name,
        team_plan_name: plan,
        team_plan_users: quota,
        team_plan_status: status,
        team_plan_period: period,
        team_trial_end: this.formatDate(trialEnd),
        team_age_days: differenceInDays(new Date(), workspace.createdOn),
        created_at: this.formatDate(workspace.createdOn),
        origin_via_connect
      }

      if (this.isLoggingSample) {
        this.logService.sendLogDNA(
          'INFO',
          `Identify ${account.email} | origin_via_connect: ${origin_via_connect} | initialPlanId: ${accountSubscription?.initialPlanId}`
        )
      }

      // identify company/workspace
      this.identifyGroup(workspace.id, groupProperties)

      // TODO: Set the user's location
      // this.locationService.setLocation()

      // Track awork event that the user accessed the app
      this.qEventService.trackAppAccessedEvent().subscribe()
    })
  }

  /**
   * Unsubscribe from the track app accessed subscription
   */
  stopTrackAppAccessed(): void {
    this.trackAppAccessedSubscription?.unsubscribe()
  }

  /**
   * Formats the date so all integrations are capable to read it
   */
  formatDate(dateObj: Date): string {
    if (!dateObj) {
      return null
    } else {
      dateObj = new Date(dateObj)
      return dateObj.toISOString()
    }
    // other method which works for intercom, but not for mixpanel:
    // return Math.floor(dateObj.getTime() / 1000)
  }

  /**
   * Returns whether the app is running on localhost or in develop
   * @returns {boolean}
   */
  private isDeveloperEnvironment(): boolean {
    return environment === 'local' || environment === 'develop'
  }

  /**
   * Context event data
   */
  private setEventContextData(): {} {
    return {
      userAgent: navigator.userAgent,
      locale: navigator.language,
      page: {
        path: window.location.pathname,
        title: document.title,
        url: window.location.href,
        referrer: document.referrer
      }
    }
  }
}
